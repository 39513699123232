(function($){

	var getMapCoords = function(){
		$.ajax({
			dataType : "json",
			url: "locations.json"
		}).done(function(data){
			return data;
		});
	}

	var markers = [];

	var initializeMap = function(args) {

		var venue = new google.maps.LatLng(args.locations[0].lat, args.locations[0].lng);

		var mapOptions = {
			center: venue,
			scrollwheel: false,
			// zoom: 18,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			mapTypeControl: false,
			zoomControl: true,
			zoomControlOptions: {
				style: google.maps.ZoomControlStyle.DEFAULT
			}
		};

		var map = new google.maps.Map(document.getElementById(args.id),mapOptions);

		$.each(args.locations,function(index, value){
			
			var item = $(this)[0];

			var icon = {
	          url: 'build/'+item.icon,
	          size: new google.maps.Size( item.width, item.height),
	          origin: new google.maps.Point(0,0),
	          anchor: new google.maps.Point( item.width/2, item.height)
	        };
			
			var marker = new google.maps.Marker({
				position: new google.maps.LatLng(item.lat, item.lng),
				map: map,
				icon: icon,
				animation: google.maps.Animation.DROP,
				title:item.title
			});

			marker.addListener('click', function() {
	       		map.setZoom(16);
	        	map.setCenter( marker.getPosition() );

	        });

			markers.push(marker);

		});

		var bounds = new google.maps.LatLngBounds();

		for (var i = 0; i < markers.length; i++) {
			bounds.extend(markers[i].getPosition());
		}

		map.fitBounds(bounds);

		$(window).resize(function(){
			google.maps.event.trigger(map, 'resize');
		});
	}

	$('.primary-map-locations a').hover(function(){
		var i = $(this).parent().index();

		if (markers[i].getAnimation() !== null) {
			markers[i].setAnimation(null);
		} else {
			markers[i].setAnimation(google.maps.Animation.BOUNCE);
		}
	},function(){
		var i = $(this).parent().index();
		markers[i].setAnimation(null);
	});

	if( $('.gmap').length ){
		

		$.ajax({
			dataType : "json",
			url: "locations.json"
		}).done(function(data){
			console.log('yo');
			initializeMap({ locations: data.locations, id: 'gmap' });
		});

	}

	new WOW().init();

	$('form').submit(function() {
		if (grecaptcha.getResponse() == ""){
			alert("Please complete recaptcha!");
			return false
		}
	});

	$('.toggle').click(function(e) {
  		e.preventDefault();
  
		var $this = $(this);

		if ($this.next().hasClass('show')) {
		    $this.next().removeClass('show');
		    $this.next().slideUp(350);
		} else {
		    $this.parent().parent().find('li .inner').removeClass('show');
		    $this.parent().parent().find('li .inner').slideUp(350);
		    $this.next().toggleClass('show');
		    $this.next().slideToggle(350);
		}
	});

	$('.main-header-nav nav').clone().appendTo('.mobile-navbar');

	$('.mobile-toggle').click(function(){
		$('.mobile-navbar').fadeIn(500);
	});

	$('.mobile-navbar .close').on('click touch',function(){
		$('.mobile-navbar').fadeOut(500);
	});

	$('.accommodation-slideshow').cycle();

	$('.accommodation-pager a').click(function(){
		var slide = $(this).data('slide');
		$('.accommodation-slideshow').cycle('goto', parseInt(slide) );
		$('html, body').animate({
	        scrollTop: $(".accommodation-slideshow").offset().top - 40
	    }, 300);
		return false;
	});

	$('.panel-button').on('click',function(){
		var target = $(this).attr('href');
		$('html, body').animate({
	        scrollTop: $(target).offset().top
	    }, 700);
		return false;
	});

	// Fancybox preserve aspect ratio

	$('.slide-video').fitVids();

	$('[data-fancybox="video"]').fancybox({
	  afterLoad : function( instance, current ) {

	     // Remove scrollbars and change background
	    current.$content.css({
	    	overflow   : 'visible',
	    	background : '#000'
	    });
				    
	  },
	  onUpdate : function( instance, current ) {
	    var width,
	        height,
	        ratio = 16 / 9,
	        video = current.$content;
	    
	    if ( video ) {
	    	video.hide();

	    	width  = current.$slide.width();
	    	height = current.$slide.height() - 100;

	    	if ( height * ratio > width ) {
	    		height = width / ratio;
	    	} else {
	    		width = height * ratio;
	    	}

	    	video.css({
	    		width  : width,
	    		height : height
	    	}).show();

	    }
	}
})

})(jQuery);